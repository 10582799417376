// import { notificationContainer } from 'untils/notificationContainer';
import heic2any from 'heic2any';

export async function fetchWithAuthentication(url: string, authToken: string) {
	const headers = new Headers();
	headers.set('Authorization', `Bearer ${authToken}`);
	try {
		return fetch(url, { headers });
	} catch (error) {
		console.log(error);
	}
}

async function setImg(
	ref: any,
	link: string | undefined,
	setLoader: (value: boolean) => void,
	response: Response | undefined,
) {
	if (response) {
		const blob = await response.blob();

		const objectUrl = window.URL.createObjectURL(blob);
		const result = blob.type.includes('heif')
			? await heic2any({
					blob: blob,
					toType: 'image/jpeg',
			  })
			: null;
		setLoader(false);
		if (ref.current) {
			link
				? ref.current.setAttribute('href', objectUrl)
				: ref.current.setAttribute(
						'src',
						(blob.type.includes('heif') || blob.type.includes('heic')) && result instanceof Blob
							? window.URL.createObjectURL(result)
							: objectUrl,
				  );
		}
	}
}

export async function displayProtectedImage(
	ref: any,
	imageUrl: string,
	authToken: string,
	setLoader: (value: boolean) => void,
	link?: string,
) {
	setLoader(true);
	// Fetch the image.

	try {
		const response = await fetchWithAuthentication(imageUrl, authToken);

		if (!response?.ok) {
			const response = await fetchWithAuthentication(
				imageUrl.replace('photo', 'document'),
				authToken,
			);

			setImg(ref, link, setLoader, response);
		} else {
			setImg(ref, link, setLoader, response);
		}
	} catch (error) {
		console.log(error);
	}
}

import classNames from 'classnames';
import { IParams } from 'pages/Dashboard/EditAiChatBot/EditAiChatBot';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import { ELinksName } from 'services/constants/list-links-sidebar';
import { EEditAiChatBotTabs } from 'services/constants/lists-table';

export const EditAIChatBotTabs: FC<{ params: IParams }> = ({ params }) => {
	return (
		<div className="tabs-buttons-nav">
			<Link
				to={`${ELinksName.EDIT_BOT}/${params.id}/${params.name}/${EEditAiChatBotTabs.MAIN}`}
				className={classNames('tabs-buttons-nav-link', {
					active: params.tab === EEditAiChatBotTabs.MAIN || !params.tab,
				})}
			>
				Main
			</Link>
			<Link
				to={`${ELinksName.EDIT_BOT}/${params.id}/${params.name}/${EEditAiChatBotTabs.STRUCTURE}`}
				className={classNames('tabs-buttons-nav-link', {
					active: params.tab === EEditAiChatBotTabs.STRUCTURE,
				})}
			>
				Structure
			</Link>
			<Link
				to={`${ELinksName.EDIT_BOT}/${params.id}/${params.name}/${EEditAiChatBotTabs.APPEARANCE}`}
				className={classNames('tabs-buttons-nav-link', {
					active: params.tab === EEditAiChatBotTabs.APPEARANCE,
				})}
			>
				Appearance
			</Link>{' '}
			<Link
				to={`${ELinksName.EDIT_BOT}/${params.id}/${params.name}/${EEditAiChatBotTabs.TEXT_TEMPLATE}`}
				className={classNames('tabs-buttons-nav-link', {
					active: params.tab === EEditAiChatBotTabs.TEXT_TEMPLATE,
				})}
			>
				Text template
			</Link>
			{/* <Link
				to={`${ELinksName.EDIT_BOT}/${params.id}/${params.name}/${EEditAiChatBotTabs.IMAGE_TEMPLATE}`}
				className={classNames('tabs-buttons-nav-link', {
					active: params.tab === EEditAiChatBotTabs.IMAGE_TEMPLATE,
				})}
			>
				Image templates
			</Link> */}
		</div>
	);
};

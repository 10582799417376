import { CloseSvg } from 'assets/svg-icon';
import classNames from 'classnames';
import { SmallLoader } from 'components/SmallLoader';
import { FC, MouseEvent, ReactNode, useEffect } from 'react';

interface IProps {
	title: string;
	btnName: string;
	btnNameTwo?: string;
	children?: ReactNode;
	isOpenModal?: () => void;
	handleButton?: (event: MouseEvent) => void;
	disablet?: string | Array<string>;
	value?: string;
	loader?: boolean;
}

export const PopUpWrapper: FC<IProps> = ({
	title,
	children,
	btnName,
	btnNameTwo,
	isOpenModal,
	handleButton,
	disablet,
	loader,
}) => {
	useEffect(() => {
		document.body.style.overflow = 'hidden';
		return () => {
			document.body.style.overflow = '';
		};
	}, []);
	return (
		<div className="popup-container">
			<div className="popup-box">
				<button type="button" onClick={isOpenModal} className="popup-box__close">
					<CloseSvg />
				</button>
				{title ? <div className="popup-box__title">{title}</div> : null}
				{children}
				{btnName ? (
					<button
						id="add"
						disabled={!disablet}
						className={classNames('popup-box__btn-close', {
							'cursor-not-allowed': !disablet,
						})}
						onClick={handleButton}
						type="button"
					>
						{loader ? <SmallLoader /> : btnName}
					</button>
				) : null}
				{btnNameTwo ? (
					<button
						id="add"
						className={classNames('popup-box__btn-border')}
						onClick={isOpenModal}
						type="button"
					>
						{loader ? <SmallLoader /> : btnNameTwo}
					</button>
				) : null}
			</div>
		</div>
	);
};

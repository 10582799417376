import { createSelector } from '@reduxjs/toolkit';
import { IStoreState } from 'redux/types';
import {
	IAiChatBotsResponse,
	IAiChatBotsState,
	IBot,
	IBotInfoResponse,
	IBotPromoCodeListResonse,
	IButtonBot,
	ICategory,
	ICommandAllData,
	IListLogo,
	IPricingResponse,
	IRecommendedResponse,
	IResponseTemplateText,
	IStatisticsBot,
	ITotalStatisticsResponse,
} from './types';

const getAiChatBotsState = (state: IStoreState): IAiChatBotsState => state.aiChatBots;

export const getAiChatBotsSelector = createSelector(
	[getAiChatBotsState],
	(state: IAiChatBotsState): null | IAiChatBotsResponse => state?.aiChatBots,
);

export const getAiLoaderSelector = createSelector(
	[getAiChatBotsState],
	(state: IAiChatBotsState): boolean => state?.aiChatBotLoader,
);

export const getPricingLoaderSelector = createSelector(
	[getAiChatBotsState],
	(state: IAiChatBotsState): boolean => state.pricingLoader,
);

export const getBotsStatisticsSelector = createSelector(
	[getAiChatBotsState],
	(state: IAiChatBotsState): IStatisticsBot | null => state?.statistics,
);

export const getTotalStatisticsSelector = createSelector(
	[getAiChatBotsState],
	(state: IAiChatBotsState): ITotalStatisticsResponse | null => state?.totalStatistics,
);

export const getPricingSelector = createSelector(
	[getAiChatBotsState],
	(state: IAiChatBotsState): IPricingResponse | null => state?.pricing,
);

export const getStatisticsSelector = createSelector(
	[getAiChatBotsState],
	(state: IAiChatBotsState): IStatisticsBot | null => state?.statistics,
);

export const getCategoryBotSelector = createSelector(
	[getAiChatBotsState],
	(state: IAiChatBotsState): Array<ICategory> | null => state?.category,
);

export const getBotDataSelector = createSelector(
	[getAiChatBotsState],
	(state: IAiChatBotsState): IBot | null => state?.bot,
);

export const getCommandsSelector = createSelector(
	[getAiChatBotsState],
	(state: IAiChatBotsState): ICommandAllData | null => state?.commands,
);

export const getButtonsSelector = createSelector(
	[getAiChatBotsState],
	(state: IAiChatBotsState): IButtonBot | null => state?.buttons,
);

export const getCommandsInfoSelector = createSelector(
	[getAiChatBotsState],
	(state: IAiChatBotsState): ICommandAllData | null => state?.commandsInfo,
);

export const getRecommendedSelector = createSelector(
	[getAiChatBotsState],
	(state: IAiChatBotsState): IRecommendedResponse | null => state.recommended,
);

export const getBackgroundLoaderSelector = createSelector(
	[getAiChatBotsState],
	(state: IAiChatBotsState): boolean => state.uploadBackgroundLoader,
);

export const getLogoLoaderSelector = createSelector(
	[getAiChatBotsState],
	(state: IAiChatBotsState): boolean => state.uploadLogoLoader,
);

export const getBotPromoCodeSelector = createSelector(
	[getAiChatBotsState],
	(state: IAiChatBotsState): null | IBotPromoCodeListResonse => state.botsList,
);

export const getBotPromoCodeInfoSelector = createSelector(
	[getAiChatBotsState],
	(state: IAiChatBotsState): null | IBotInfoResponse => state.botInfo,
);

export const getListAvatarsSelector = createSelector(
	[getAiChatBotsState],
	(state: IAiChatBotsState): null | Array<IListLogo> => state.listLogoImg,
);

export const getTemplateTextSelector = createSelector(
	[getAiChatBotsState],
	(state: IAiChatBotsState): null | IResponseTemplateText => state.listTemplateText,
);

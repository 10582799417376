import { ChangeEvent, FC, KeyboardEvent, useState } from 'react';
import { firstUppercaseLetter } from 'untils/firstUppercaseLetter';
import { IResponseTemplateText, ITemplateText } from 'redux/reducers/ai-chat-bot/types';
import { Input } from 'ui/Input';
import { Textarea } from 'ui/Textarea/Textarea';
import { useDispatch } from 'react-redux';
import { editTemplateTextRequest } from 'redux/reducers/ai-chat-bot/reducer';

interface IProps {
	structure: ITemplateText;
	templates: IResponseTemplateText;
	text: string;
}

export const Accordion: FC<IProps> = ({ templates, structure, text }) => {
	const dispatch = useDispatch();

	const [name, setName] = useState<string>(structure.name);
	const [desc, setDesc] = useState<string>(structure.prompt);
	const [option] = useState<number>(structure.option);

	const handleInput = (event: ChangeEvent) => {
		const { value, name } = event.target as HTMLInputElement;

		name === 'name' ? setName(value) : setDesc(value);
	};

	const onEditTemplate = (event: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		const { code } = event;

		const data = {
			bot_id: templates.bot_id,
			templates: templates.templates.map((item) => {
				if (item.option === option) {
					return {
						name,
						prompt: desc,
						option,
					};
				}

				return item;
			}),
		};

		code === 'Enter' && dispatch(editTemplateTextRequest(data));
	};

	return (
		<div className="accordion">
			<div className={`accordion__box${text ? `-${text}` : ''}`}>
				<div className="accordion__left-content">Title</div>
				<div className="accordion__right-content">
					{/* <Editor styles={{ minHeight: 'auto' }} /> */}
					<Input
						name={'name'}
						type={'text'}
						handleInput={handleInput}
						onKeyDownInput={onEditTemplate}
						value={name}
						placeholder={'Name'}
					/>
				</div>
			</div>
			<div className={`accordion__box${text ? `-${text}` : ''}`}>
				<div className="accordion__left-content">Code</div>
				<div className="accordion__right-content">
					{/* <Editor styles={{ minHeight: 'auto' }} /> */}
					<Textarea
						handleTextarea={handleInput}
						onKeyDownInput={onEditTemplate}
						name={'desc'}
						placeholder={'Template'}
						value={desc}
					/>
				</div>
			</div>
			<div className="warning">To change the text in the field, press the enter button.</div>
			{/* {structure.code ? (
				<div className="accordion__box">
					<div className="accordion__left-content">{structure.code}</div>
					<div className="accordion__right-content">
						<Editor styles={{ minHeight: 'auto' }} />
					</div>
				</div>
			) : null} */}
		</div>
	);
};

import { ChangeEvent, FC, useState } from 'react';
import { Appearance } from 'components/AIChatBotComponents/CreateBotTabs/Appearance/Appearance';
import { CreateBotTabs } from 'components/AIChatBotComponents/CreateBotTabs/CreateBotTabs';
import { Main } from 'components/AIChatBotComponents/CreateBotTabs/Main/Main';
import { Structure } from 'components/AIChatBotComponents/CreateBotTabs/Structure/Structure';
import { Template } from 'components/AIChatBotComponents/CreateBotTabs/Template/Template';
// import { Loader } from 'components/Loader';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { getButtonsSelector, getCategoryBotSelector } from 'redux/reducers/ai-chat-bot/selectors';
import { ETabsCreateBot, ETypeBot } from 'services/constants/lists-table';
import './CreateBot.scss';
import { structureData } from '../../../components/AIChatBotComponents/Structure/data';

import { ArrowBackSvg } from 'assets/svg-icon';
import { createBotRequest } from 'redux/reducers/ai-chat-bot/reducer';

export const CreateBot: FC = () => {
	const [category, setCategory] = useState<string>('');
	const [name, setName] = useState<string>('');
	const [username, setUsername] = useState<string>('');
	const categoryList = useSelector(getCategoryBotSelector);
	const buttons = useSelector(getButtonsSelector);
	const dispatch = useDispatch();
	const params = useParams();
	const navigation = useNavigate();

	console.log(params);

	const onSelect = (value: string) => {
		setCategory(value);
	};

	const onEditButton = () => {};

	const onEditBot = () => {
		if (ETypeBot.CREATE_EDIT_IMAGE_BOT === params.name) {
			return false;
		}
		return true;
	};

	const onPhotoBot = () => {
		if (
			ETypeBot.CREATE_IMAGE_BOT === params.name ||
			ETypeBot.CREATE_EDIT_IMAGE_BOT === params.name ||
			ETypeBot.CREATE_TEXT_IMAGE_BOT === params.name
		) {
			return false;
		}
		return true;
	};
	const onTextBot = () => {
		if (
			ETypeBot.CREATE_TEXT_BOT === params.name ||
			ETypeBot.CREATE_TEXT_IMAGE_BOT === params.name
		) {
			return false;
		}
		return true;
	};

	const onDocumentBot = () => {
		if (
			ETypeBot.CREATE_IMAGE_BOT === params.name ||
			ETypeBot.CREATE_EDIT_IMAGE_BOT === params.name ||
			ETypeBot.CREATE_TEXT_IMAGE_BOT === params.name ||
			ETypeBot.CREATE_TEXT_BOT === params.name
		) {
			return false;
		}
		return true;
	};

	const createBot = () => {
		dispatch(
			createBotRequest({
				data: {
					hide_edit_bot: onEditBot(),
					hide_photo_bot: onPhotoBot(),
					hide_text_bot: onTextBot(),
					show_document_bot: onDocumentBot(),
					name,
					username,
				},
				history: navigation,
			}),
		);
	};

	const onInputs = (event: ChangeEvent) => {
		const { value, name } = event.target as HTMLInputElement;
		name === 'name' ? setName(value) : setUsername(value);
	};

	const definePage = () => {
		switch (params.tab) {
			case ETabsCreateBot.STRUCTURE:
				return <Structure onEditButton={onEditButton} buttons={structureData} keyBtn={''} />;
			case ETabsCreateBot.APPEARANCE:
				return <Appearance />;
			case ETabsCreateBot.TEMPLATE:
				return <Template />;
			default:
				return (
					<Main
						username={username}
						name={name}
						onInputs={onInputs}
						categoryList={categoryList}
						onSelect={onSelect}
					/>
				);
		}
	};

	return (
		<div className="main-content">
			<div className="title-block-wrap">
				<div className="title-block">
					<div className="header-title">
						<div className="header-title-flex">
							<button type="button" className="back-button" onClick={() => window.history.go(-1)}>
								<ArrowBackSvg />
							</button>
							<p className="title">New AI BOT</p>
						</div>
						<button
							disabled={!(name && username)}
							onClick={createBot}
							className="add-btn"
							type="button"
						>
							Create
						</button>
					</div>
				</div>
			</div>
			<CreateBotTabs />
			<div className="table-block">
				<div className="edit-bot">
					<div className="title-create-bot">
						{params.name?.replace('Create_', '').replaceAll('_', ' ')}
					</div>
					{definePage()}
				</div>
			</div>
		</div>
	);
};
